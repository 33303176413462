import React from 'react';
import { cx, css } from 'linaria';
import { theme } from '../Theme';

const htmlStyle = css`
  padding: 0 1rem;
  .youtube-embed {
    display: none;
  }

  h1,
  h2,
  h3,
  h4 {
    text-transform: uppercase;
    letter-spacing: 0;
  }
  h1 {
    font-size: 1.75rem;
    line-height: 1.4;
  }

  h2 {
    padding-top: 10px;
    font-size: 1rem;
    margin-bottom: 0.4rem;
  }
  h3 {
    padding-top: 10px;
  }

  h4 {
    padding-top: 10px;
  }

  p {
    font-size: 1rem !important;
    margin-bottom: 1rem;
    span {
      font-size: 1rem !important;
    }
  }

  ul {
    font-size: 0.85rem;
    list-style-type: disc;
    margin-left: 1rem;
  }

  td {
    font-size: 0.85rem;
    strong {
    }
  }
  strong,
  b {
    font-weight: bold;
  }

  a {
    text-decoration: none;
    transition: all ease 0.3s;
    &:hover {
      color: #000;
      transition: all ease 0.3s;
    }
  }
  ${theme.below.md} {
    margin: 0;
    margin-top: 5rem;
    width: 100%;
  }
`;

export const HtmlRow = ({ html }) => {
  return (
    <div className={cx('html-row-item-outer', htmlStyle)} style={{ flex: '1' }}>
      <div
        className={cx('html-row-item-wrapper')}
        dangerouslySetInnerHTML={{
          __html: html?.value
        }}
      />
    </div>
  );
};
