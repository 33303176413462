import React from 'react';
import { styled } from 'linaria/react';
import { ContentPlacer } from './ContentPlacer';
import { cx } from 'linaria';
import { theme } from '../Theme';

const BoxContentWrapper = styled('div')`
  h1,
  h2,
  span,
  p,
  .content-text {
    color: ${props => props.color};
    font-family: ${theme.fonts.primary};
  }

  h1 {
    font-size: ${theme.fontSizes.header};
    line-height: ${theme.lineHeights.header};
    font-weight: ${theme.fontWeights.semibold};
    letter-spacing: 0;
  }

  h2 {
    font-size: ${theme.fontSizes.smallHeader};
    line-height: ${theme.lineHeights.smallHeader};
    font-weight: ${theme.fontWeights.semibold};
  }

  &.shadow {
    .content-text,
    h1,
    h2 {
      text-shadow: 0px 0px 13px ${props => props.shadowcolor};
    }
  }

  .content-text {
    font-weight: ${theme.fontWeights.semibold};
  }

  .sub-heading {
    font-size: 14px;
    display: block;
  }

  a {
    text-decoration: none;
  }

  strong {
    font-weight: 700;
  }

  .buttons-row {
    display: flex;
    width: 100%;
    margin-top: 32px;

    > * + * {
      margin-left: 10px;
    }
  }

  &.image-box {
    .content-text {
      margin-top: 8px;
    }

    .buttons-row {
      margin-top: 16px;
    }
  }

  &.ALIGN_LEFT {
    text-align: left;

    .buttons-row {
      justify-content: flex-start;
    }
  }
  &.ALIGN_CENTER {
    text-align: center;

    .buttons-row {
      justify-content: center;
    }
  }

  &.ALIGN_RIGHT {
    text-align: right;

    .buttons-row {
      justify-content: flex-end;
    }
  }
`;

export const BoxContent = ({
  verticalPosition,
  horizontalPosition,
  textColor,
  textShadowColor,
  text,
  header,
  subHeading,
  textAlignment,
  buttonChildren,
  forType
}) => {
  const isHero = forType === 'hero';
  return (
    <ContentPlacer
      verticalPosition={verticalPosition}
      horizontalPosition={horizontalPosition}
    >
      <BoxContentWrapper
        color={textColor?.value ?? '#fff'}
        shadowcolor={textShadowColor?.value ?? 'black'}
        className={cx(
          forType,
          textAlignment?.value,
          textShadowColor?.value && 'shadow'
        )}
      >
        {subHeading?.value && (
          <span className="sub-heading">{subHeading?.value}</span>
        )}
        {header?.value && (
          <>
            {isHero ? (
              <h1
                dangerouslySetInnerHTML={{
                  __html: header?.value
                }}
              />
            ) : (
              <h2
                dangerouslySetInnerHTML={{
                  __html: header?.value
                }}
              />
            )}
          </>
        )}
        {text?.value && (
          <div
            className="content-text"
            dangerouslySetInnerHTML={{
              __html: text?.value
            }}
          />
        )}

        {buttonChildren && <div className="buttons-row">{buttonChildren}</div>}
      </BoxContentWrapper>
    </ContentPlacer>
  );
};
