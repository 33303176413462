import React from 'react';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import Image from '@jetshop/ui/Image';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
const PromoteBoxRowLinkItemWrapper = styled('div')`
  overflow: hidden;
  border-radius: 4px;

  [data-flight-image-children] {
    padding: 1rem;
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: start;
    align-items: end;
    h4 {
      font-size: 24px;
      line-height: 1;
      color: ${theme.colors.black};
      font-family: 'Graphik';
    }
  }

  :hover,
  :active {
    /* border: 1px solid red; */
    [data-flight-image-container] {
      filter: brightness(1.05);
    }
  }
`;

export const PromoteBoxRowLinkItem = ({
  link,
  image,
  imageAlt,
  text,
  totalChildren
}) => {
  return (
    <PromoteBoxRowLinkItemWrapper className="promote-box-row-link-item">
      <LinkEvaluator link={link}>
        <Image
          alt={imageAlt?.value}
          src={image?.value}
          aspect={totalChildren?.value > 2 ? '1:1' : '2:1'}
          cover
        >
          <h4>{text?.value}</h4>
        </Image>
      </LinkEvaluator>
    </PromoteBoxRowLinkItemWrapper>
  );
};
