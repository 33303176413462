import React from 'react';
import { theme } from '../Theme';
import { styled } from 'linaria/react';

const PromoteBoxRowTextItemWrapper = styled('div')`
  min-height: 300px;
  color: ${theme.colors.black};
  border-radius: 4px;

  ${theme.below.lg} {
    min-height: 0;
    padding-bottom: 6px;
  }
  .sub-heading {
    font-family: 'Graphik';
    font-size: 14px;
    display: block;
    color: inherit;
    font-weight: ${theme.fontWeights.light};
  }

  h2 {
    font-size: 46px;
    line-height: 1;
    font-weight: ${theme.fontWeights.semibold};
    color: inherit;
    font-family: 'Graphik';
  }

  .content-text {
    color: inherit;
    font-family: 'Graphik';
    font-weight: ${theme.fontWeights.regular};
  }
`;

export const PromoteBoxRowTextItem = ({ text, header, subHeading }) => {
  return (
    <PromoteBoxRowTextItemWrapper className={'promote-box-row-text-item'}>
      {subHeading?.value && (
        <span className="sub-heading">{subHeading?.value}</span>
      )}
      {header?.value && (
        <h2
          dangerouslySetInnerHTML={{
            __html: header?.value
          }}
        />
      )}

      {text?.value && (
        <div
          className="content-text"
          dangerouslySetInnerHTML={{
            __html: text?.value
          }}
        />
      )}
    </PromoteBoxRowTextItemWrapper>
  );
};
