import React from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as FilledStar } from '../../../svg/FilledStar.svg';
import { ReactComponent as HalfStar } from '../../../svg/HalfStar.svg';
import { ReactComponent as NotFilledStar } from '../../../svg/NotFilledStar.svg';

const LipscoreWrapper = styled('div')`
  text-align: center;
  text-align: -webkit-center;
  min-height: 17px;

  .lipscore-rating-small {
    display: flex;
    justify-content: left;
    white-space: nowrap;
    height: 17px;

    svg {
      height: 17px;
      width: 17px;
    }
  }

  .lipscore-visually-hidden {
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
  }

  .lipscore-rating-amount {
    font-size: 10px;
    font-weight: bold;
    margin-left: 5px;
    margin-top: 1px;
    line-height: 18px;

    span {
      color: #999;
      font-weight: normal;
    }
  }
`;
const StarsWrapper = styled('div')`
  display: flex;
  justify-content: center;
`;

export const LipscoreStars = ({ rating }) => {
  let stars = [];
  let numbersOfStars = 5;

  for (let y = 0; y < rating; y++) {
    //Check if rating has decimals
    if (rating % 1 !== 0) {
      //If rating is higher than loop value but lower than the next loop integer: push a half star
      if (y === Math.floor(rating) && y !== rating) {
        stars.push(<HalfStar key={y + rating} />);
      } else {
        stars.push(<FilledStar key={y + rating} />);
      }
    } else {
      stars.push(<FilledStar key={y + rating} />);
    }
  }
  //Fill the remaining stars with non-filled stars
  if (stars.length < 5) {
    for (let i = stars.length; i < numbersOfStars; i++) {
      stars.push(<NotFilledStar key={i + rating} />);
    }
  }

  return <StarsWrapper>{stars}</StarsWrapper>;
};

const LipscoreSmallRating = ({ rating, votes }) => {
  if (!rating) {
    return null;
  }
  var roundedRating = Math.round(rating * 10) / 10;
  if (rating > 3.5)
    return (
      <LipscoreWrapper>
        <span className="lipscore-rating-small">
          <span className="lipscore-visually-hidden" />
          <LipscoreStars rating={rating} />
          <span className="lipscore-rating-amount">
            {roundedRating} <span>({votes})</span>
          </span>
        </span>
      </LipscoreWrapper>
    );
  return null;
};

export default LipscoreSmallRating;
