import { Above } from '@jetshop/ui/Breakpoints';
import React from 'react';
// import { ScrollSnapper } from '../ui/designUpdate/ScrollSnapper';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import GridProduct from '../CategoryPage/GridProduct';
import LipscoreApiCall from '../ProductPage/Lipscore/LipscoreApiCall';
import { SliderWithButtons } from '../ui/SharedSlider';

const ProductRowWrapper = styled('div')`
  width: 100%;
  padding: 0 0px;
  max-width: calc(80rem + 20px);
  margin: auto;
  .scroll-snapper-item {
    > * {
      width: 100%;
      margin-bottom: 2rem;
    }
    a {
      margin: 0;
    }
  }

  .product-card {
    width: 100%;
  }
  .slick-arrow.slick-next {
    right: 0;
    ${theme.below.lg} {
      right: 10px;
    }
  }
  .slick-arrow.slick-prev {
    left: 0;
    ${theme.below.lg} {
      left: 10px;
    }
  }
  .shared-slider-wrapper {
    margin: 0 !important;
  }
  .slick-list {
    width: 100% !important;
  }
`;

export const ProductRow = ({ children }) => {
  const buyableChildren = children?.filter(child => {
    return child?.props?.product?.value?.stockStatus?.buyable;
  });
  const productList = buyableChildren?.map(child => {
    return child?.props?.product?.value;
  });

  return (
    <Above breakpoint="lg">
      {matches => (
        <LipscoreApiCall products={productList}>
          {productsWithRating => {
            const ratedProductChildren = buyableChildren?.map(child => {
              // this sucks but works
              return {
                ...child,
                props: {
                  ...child?.props,
                  product: {
                    ...child?.props?.product,
                    value:
                      productsWithRating?.find(
                        prod =>
                          prod?.articleNumber ===
                          child?.props?.product?.value?.articleNumber
                      ) ?? child?.props?.product?.value
                  }
                }
              };
            });

            const pages = [];
            React.Children.forEach(ratedProductChildren, (child, index) => {
              const pageIndex = Math.floor(index / 2);
              if (!pages[pageIndex]) {
                pages[pageIndex] = [];
              }
              pages[pageIndex].push(child);
            });

            return (
              <ProductRowWrapper>
                <SliderWithButtons desktopSlides={4} mobileSlides={2}>
                  {pages.map((page, index) => (
                    <div key={index}>{page}</div>
                  ))}
                </SliderWithButtons>
              </ProductRowWrapper>
            );
          }}
        </LipscoreApiCall>
      )}
    </Above>
  );
};

export const ProductRowItem = ({ product }) => {
  return <GridProduct product={product?.value ?? product} />;
};
