import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../../Theme';

const SnapperWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  width: 100%;
  margin: 0 auto;
  max-width: ${props => props.maxwidth};

  ::-webkit-scrollbar {
    display: none;
  }

  // ::-webkit-scrollbar {
  //   width: 9px;
  //   height: 9px;
  //   background: #f3f3f3;
  // }

  // ::-webkit-scrollbar-thumb {
  //   -webkit-border-radius: 2px;
  //   border-radius: 2px;
  //   background: ${theme.colors.white};
  //   border-top: 3px solid ${theme.colors.blue};
  //   border-bottom: 3px solid ${theme.colors.red};
  // }

  .scroll-snapper-item {
    min-width: ${props => props.childwidth};
    max-width: ${props => props.childwidth};
    scroll-snap-align: start;
    list-style: none;

    &:not(:first-child) {
      margin-left: ${props => props.childgap};
    }
    ${theme.below.lg} {
      margin-left: 0px !important;
      margin-right: ${props => props.childgap};
    }
  }
`;

/**
 * The ScrollSnapper function is a component that takes in children and
 * returns them as a list of items. It also has two props, childGap and
 * childWidth, which can be used to change the gap between each item and the width of each item.
 *
 * @param {string} childGap Set the gap between each child (include unit in string e.g 20px)
 * @param {string} childWidth Set the width of each child item (include unit in string e.g 50vw)
 * @param {string} maxWidth Set the max-width of product row
 * @param children Pass in the children of the component
 *
 * @return A div that contains a series of children that can be scrolled with snapping
 *
 * @docauthor Anders Zetterstrom @ R3
 */
export const ScrollSnapper = ({
  childGap = '0px',
  childWidth = '25%',
  maxWidth = theme.maxWidth,
  children
}) => {
  const childWidthCalculationString = `calc(${childWidth} - (${childGap} / ${
    children?.length
  }) * ${children?.length - 1} )`;

  return (
    <SnapperWrapper
      childgap={childGap}
      childwidth={childWidthCalculationString}
      maxwidth={maxWidth}
      className={cx('scroll-snapper')}
    >
      {children?.map((child, index) => (
        <div key={`snap-item-${index}`} className="scroll-snapper-item">
          {child}
        </div>
      ))}
    </SnapperWrapper>
  );
};
