import ProductLink from '@jetshop/ui/ProductLink';
import ProductImage from '@jetshop/ui/ProductList/ProductImage';
import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import React from 'react';
import { Price } from '../Price';
import { theme } from '../Theme';
import { SaleBadge } from '../ui/SaleBadge';
import { ProductGridBadges } from '../ui/ProductGridBadges';
import { LipscoreStars } from '../ProductPage/Lipscore/LipscoreSmallRating';
import { Above } from '@jetshop/ui/Breakpoints';
import { ReactComponent as Star } from '../../svg/FilledStar.svg';

export const ProductWrapper = styled('div')`
  position: relative;
  width: 50%;
  margin-bottom: 1rem;
  ${theme.above.md} {
    width: 33.333%;
  }
  ${theme.above.lg} {
    width: 33.333%;
  }

  .grid-price .price {
    color: ${theme.colors.black};
    font-family: ${theme.fonts.primary};
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.0625rem;
    display: flex;
    align-items: center;
  }

  .best.price {
    font-family: inherit;
  }

  .variant-images {
    opacity: 0;
    visibility: hidden;
    transition: all, 0.2s ease;
  }

  &:hover {
    .variant-images {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const ProductBlockLink = styled(ProductLink)`
  text-decoration: none;
  color: inherit;
  display: block;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 36px;
  background-color: white;
  ${theme.below.md} {
    margin-bottom: 0;
  }
`;
export const ProductDetails = styled('div')`
  display: block;
  background-color: white;
  padding: 5px 0;
  max-width: 100%;
  max-height: 100%;
  position: relative;

  a {
    color: ${theme.colors.secondaryblue};
  }

  .sale-badge {
    top: auto;
    bottom: calc(100% + 1rem);
  }
`;

export const Name = styled('h4')`
  ${'' /* height: 27px; */}
  color: ${theme.colors.black};
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  margin-top: 4px;

  ${theme.below.sm} {
    margin-bottom: 6px;
    font-size: 0.6rem;
    line-height: 1.2;
  }
`;

const StyledProductImage = styled('div')`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.02);
    z-index: 1;
    pointer-events: none;
  }
  > div > div {
    > div {
      position: absolute;
      bottom: 0;
      left: 0;
      top: auto;
      right: auto;
      width: auto;
      height: auto;

      > div {
        position: relative;

        > div {
          background: black;
          color: white;
          font-size: 11px;
          padding: 3px 8px;
          margin: 3px;
          display: inline-block;

          &.with-badge {
            background: none;
          }

          &.new {
            background: black;
            color: white;
          }
          &.black {
            background: black;
            color: white;
          }
          &.RED {
            background: ${theme.colors.red};
            color: white;
          }
          &.blue {
            background: #0033a0;
            color: white;
          }
        }
      }
    }
  }
`;

const TitleRow = styled('div')`
  text-align: left;
  margin-bottom: 10px;
  .product-brand {
    display: block;
    font-size: 12px;
    font-weight: bold;
    font-family: ${theme.fonts.primary};
    margin-bottom: 0;
  }
  .product-name {
    font-size: 12px;
    font-family: ${theme.fonts.primary};
    line-height: 1.2;
    font-weight: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const PriceWrapper = styled('div')`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .price-and-discount {
    display: flex;
    align-items: flex-start;
    position: relative;
  }

  .sale-badge {
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    border: 1px solid #e1142d;
    background: white;
    color: #e1142d;
    font-size: 14px;
    margin-left: 12px;
    margin-top: 1px;
    ${theme.below.md} {
      font-size: 11px;
      padding: 1px 8px;
      margin-top: 0px;
    }
  }

  [data-flight-price] {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }

  .new-price {
    color: #e1142d;
    font-family: ${theme.fonts.primary};
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    ${theme.below.md} {
      font-size: 18px;
    }
  }
  .old-price {
    color: #939393 !important;
    font-size: 1rem;
    font-weight: normal;
    font-family: ${theme.fonts.primary};
    margin-top: 1px;
    ${theme.below.md} {
      font-size: 14px;
    }
  }
  .price {
    color: #e1142d;
    font-family: ${theme.fonts.primary};
    font-size: 20px;
    font-weight: bold;
  }
`;

const Column = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .right-column {
    flex: none;
    font-size: 12px;
  }
  .lipscore-column {
    display: flex;
    align-items: center;
    gap: 5px;
    .star {
      display: flex;
      margin-top: -2px;
      svg {
        width: 14px;
        height: 14px;
      }
    }
    .rating {
      font-weight: bold;
      color: black;
    }
    .votes {
      font-weight: normal;
      color: #939393;
    }
  }
  .color-column {
    flex: none;
    ${theme.below.md} {
      position: absolute;
      bottom: 10px;
      right: 0;
      line-height: 1;
    }
    span {
      display: block;
      font-size: 12px;
      color: #939393;
    }
  }
`;

const FromPrice = styled('span')`
  color: #e1142d;
  font-family: Graphik;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;
  margin-top: 6px;
  margin-right: 5px;
`;

const GridProduct = ({
  product,
  categoryPath,
  loading,
  className = '',
  loadImageEagerly = false
}) => {
  const variants = product?.variants?.values;
  const brandName = product?.customFields?.find(field => field.key === 'Brand')
    ?.stringValue;
  const colorCount = product?.variants?.options?.find(
    option => option.name === 'color'
  )?.values?.length;

  //Calculate which varaiant has the highest price reduction ( or defaults to product )
  const getHighestReducedPriceVariant = variants => {
    let hightestPriceReductionVariant = product;
    variants?.forEach(variant => {
      if (
        variant?.previousPrice?.incVat - variant?.price?.incVat >
        hightestPriceReductionVariant?.previousPrice?.incVat -
          hightestPriceReductionVariant?.price?.incVat
      ) {
        hightestPriceReductionVariant = variant;
      }
    });

    return hightestPriceReductionVariant;
  };

  const highestReducedPriceProduct = getHighestReducedPriceVariant(variants);

  return (
    <ProductWrapper
      data-testid="product"
      className={`product-card ${className}`}
    >
      <ProductBlockLink
        product={product}
        categoryPath={categoryPath}
        style={
          loading
            ? {
                opacity: '0'
              }
            : null
        }
      >
        <StyledProductImage>
          {highestReducedPriceProduct?.previousPrice ? (
            <SaleBadge
              price={highestReducedPriceProduct?.price}
              previousPrice={highestReducedPriceProduct?.previousPrice}
            />
          ) : null}
          <ProductImage
            sizes="400"
            aspect={'1:1'}
            image={product?.images?.[0]}
            critical={loadImageEagerly}
          />
          <ProductGridBadges badges={product?.badges} />
        </StyledProductImage>
        <ProductDetails>
          <TitleRow>
            <Column>
              {brandName && <span className="product-brand">{brandName}</span>}
              {product?.rating && (
                <div className="lipscore-column right-column">
                  <span className="star">
                    <Above breakpoint="lg">
                      {matches =>
                        matches ? (
                          <LipscoreStars rating={product?.rating} />
                        ) : (
                          <Star style={{ marginTop: '-4px' }} />
                        )
                      }
                    </Above>
                  </span>
                  <span className="rating">
                    {parseFloat(product?.rating).toFixed(1)}
                  </span>
                  <span className="votes">({product?.votes})</span>
                </div>
              )}
            </Column>
            <h3 className="product-name">
              {brandName
                ? product?.name?.replace(brandName, '')
                : product?.name}
            </h3>
          </TitleRow>
          <PriceWrapper>
            <div className="price-and-discount">
              {product?.hasVariantsWithDifferingPrices && (
                <FromPrice>{t('fr.')} </FromPrice>
              )}
              <Price
                className="grid-price"
                price={product?.price}
                recommendedPrice={product?.recommendedPrice}
                previousPrice={product?.previousPrice}
              />
            </div>
            <Column style={{ marginBottom: '3px' }}>
              {colorCount && colorCount > 1 && (
                <div className="color-column">
                  <span>
                    {colorCount} {t('colors')}
                  </span>
                </div>
              )}
            </Column>
          </PriceWrapper>
        </ProductDetails>
      </ProductBlockLink>
    </ProductWrapper>
  );
};

export default GridProduct;
