import React from 'react';
import { useState } from 'react';
import Image from '@jetshop/ui/Image';
import { styled } from 'linaria/react';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import { theme } from '../Theme';
import t from '@jetshop/intl';
import { ReactComponent as ArrowRight } from '../../svg/angle-right.svg';
// import { MaxWidth } from '../Layout/MaxWidth';
// import { MarqueeSlider } from '../ui/designUpdate/MarqueeSlider';

const BrandsRowWrapper = styled('div')`
  display: flex;
  justify-content: ${props => (props.showAll ? 'center' : 'space-between')};
  align-items: center;
  flex-wrap: ${props => (props.showAll ? 'wrap' : 'nowrap')};
  row-gap: 1rem;
  column-gap: ${props => (props.showAll ? '1rem' : '10px')};
  height: ${props => props.wrapperHeight};
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none; /* Remove scrollbar */
  -ms-overflow-style: none; /* Remove scrollbar (IE and Edge) */

  &::-webkit-scrollbar {
    display: none; /* Remove scrollbar (Chrome, Safari, and Opera) */
  }
}
  
`;

export const BrandsRow = ({ children, showAll }) => {
  const wrapperHeight = showAll ? 'auto' : '90px';
  return (
    <BrandsRowWrapper wrapperHeight={wrapperHeight} showAll={showAll}>
      {children?.map((child, index) => (
        <div className={'brand-item'} key={index}>
          {child}
        </div>
      ))}
    </BrandsRowWrapper>
  );
};

const BrandsMarqueeWrapper = styled('section')`
  max-width: ${theme.maxWidth};
  width: 100%;
  margin: 0 auto;
  background: ${props => props.backgroundcolor};
  margin-bottom: 2rem;
  padding: 0 20px;

  h3 {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 700;
    font-family: ${theme.fonts.primary};
  }

  .brand-item {
    background: ${props => props.backgroundcolor};
  }
`;

const HeaderArea = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .show-all {
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }

  p {
    font-size: 0.8rem;
    font-weight: 300;
    font-family: ${theme.fonts.primary};
  }
  .arrow {
    width: 10px;
    height: 10px;
  }
`;

export const BrandsMarquee = ({ backgroundColor, children }) => {
  const [showAll, setShowAll] = useState(false);
  const handleClick = () => setShowAll(!showAll);
  return (
    <BrandsMarqueeWrapper backgroundcolor={backgroundColor?.value ?? ''}>
      <HeaderArea>
        <h3>{t('Popular brands')}</h3>
        <div className="show-all" onClick={handleClick}>
          <p>{t('Show all')}</p>
          <ArrowRight className="arrow" />
        </div>
      </HeaderArea>
      {/* <MarqueeSlider childGap="20px">{children}</MarqueeSlider> */}
      <BrandsRow children={children} showAll={showAll} />
    </BrandsMarqueeWrapper>
  );
};

const BrandsMarqueeItemWrapper = styled('div')`
  width: 153px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 99px;
  padding: 30px;
  border: 2px solid ${theme.colors.tablegrey};
  margin: 3px 0;

  img {
    mix-blend-mode: darken;
  }
`;

export const BrandsMarqueeItem = ({ image, link }) => {
  return (
    <LinkEvaluator link={link}>
      <BrandsMarqueeItemWrapper className="brand-item">
        <Image sizes={150} src={image?.value} aspect={'3:2'} />
      </BrandsMarqueeItemWrapper>
    </LinkEvaluator>
  );
};
