import React from 'react';
import { styled } from 'linaria/react';
import { Above } from '@jetshop/ui/Breakpoints';
import { theme } from '../Theme';

const SpacerWrapper = styled('div')`
  width: 100%;
  height: ${props => props.spacerheight + 'px'};
`;

/**
 * The Spacer function is used to create a vertical space between components.
 *
 *
 * @param {String} height Sets the height of the spacer
 *
 * @return A div with a height of the value passed to it
 *
 * @docauthor Anders Zetterström @ R3
 */
export const Spacer = ({ height }) => {
  return (
    <Above breakpoint="lg">
      {matches =>
        matches ? (
          <SpacerWrapper
            className={'height-' + height?.value}
            spacerheight={theme.spaceDict[height?.value ?? 'MEDIUM']}
          />
        ) : (
          <SpacerWrapper
            className={'height-' + height?.value}
            spacerheight={theme.spaceDict[height?.value ?? 'MEDIUM'] * 0.8}
          />
        )
      }
    </Above>
  );
};
