import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../Theme';
import { Title } from './Title';
import { ScrollSnapper } from './../ui/designUpdate/ScrollSnapper';
import { Above } from '@jetshop/ui/Breakpoints';

// * CSS Start
const PopularCategoriesWrapper = styled('div')`
  width: ${theme.maxWidth};
  margin: 0 auto;

  ${theme.below.md} {
    padding-left: 20px;
    > div .left {
      padding-left: 0;
    }
  }

  &.fullwidth {
    max-width: 100%;
  }
`;

const TitleRow = styled('div')``;

const PopularCategoriesItemsWrapper = styled('div')`
  margin-top: 1.5rem;

  .scroll-snapper {
    ${theme.above.md} {
      overflow-x: hidden;
    }

    .scroll-snapper-item{
      padding-bottom: 1rem;
      position: relative;

    }
`;

// * CSS End

/**
 * The PopularCategories is a flight-component that takes in children and return them, with a scroll on mobile.
 *
 *
 * @docauthor Fredrik Svärd @ R3
 */

export const PopularCategories = ({
  title,
  fullWidth,
  mobileSlider,
  children,
  alignment,
  popularLink,
  popularLinkText
}) => {
  const gapSize = `${theme.spaceDict.XSMALL}px`;

  return (
    <>
      <PopularCategoriesWrapper
        className={cx(
          'popular-categories-wrapper',
          fullWidth && 'fullwidth',
          mobileSlider?.value ? 'slider' : 'no-slider'
        )}
      >
        <TitleRow>
          <Title
            text={title}
            alignment={alignment}
            link={popularLink}
            linkText={popularLinkText}
          />
        </TitleRow>

        <PopularCategoriesItemsWrapper>
          {/* Calls and returns the content of PopularCategoriesItem from Flight. */}
          <Above breakpoint="md">
            {matches => (
              <ScrollSnapper
                childWidth={matches ? '16.67%' : '45%'}
                childGap={gapSize}
              >
                {children}
              </ScrollSnapper>
            )}
          </Above>
        </PopularCategoriesItemsWrapper>
      </PopularCategoriesWrapper>
    </>
  );
};
