import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';

const BadgesWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 3px;
  position: absolute;
  bottom: 10px;
  left: 10px;

  .badge {
    font-size: 10px;
    background: black;
    color: white;
    display: inline-block;
    border-radius: 30px;
    padding: 1px 5px;
    &:first-letter,
    &.first-line {
      text-transform: uppercase;
    }

    &.RED {
      background: #e1142d;
      color: white;
    }
  }
`;

export const ProductGridBadges = ({ badges }) => {
  if (!badges?.length > 0) return null;
  return (
    <BadgesWrapper>
      {badges?.map((badge, index) => (
        <React.Fragment key={index}>
          {badge.url ? (
            <img src={badge.url} alt={badge.name} />
          ) : (
            <div key={index} className={cx('badge', badge?.style || '')}>
              {badge.text}
            </div>
          )}
        </React.Fragment>
      ))}
    </BadgesWrapper>
  );
};
