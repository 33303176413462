import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { theme } from '../Theme';
import Image from '@jetshop/ui/Image';
import { LinkEvaluator } from '../../utils/LinkEvaluator';

// * CSS Start
const PopularCategoriesItemContent = styled('div')`
  position: relative;
  // padding: 0.625rem;
  justify-content: center;
  align-items: flex-end;
  gap: 0.625rem;
  border-radius: 0.25rem;
  width: 100%;

  .item-image {
    z-index: 2;
  }

  .item-text {
    position: relative;
    z-index: 2;
    width: 100%;
    text-align: center;
    padding: 1rem 0 0.62rem 0;

    color: #000;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.02625rem;
    text-transform: uppercase;
    font-family: ${theme.fonts.primary};

    ${theme.below.lg} {
      font-size: 0.75rem;
      letter-spacing: 0.0225rem;
    }
  }

  .item-background-plate {
    height: 70%;
    width: 100%;
    background: #f5f5f5;
    position: absolute;
    bottom: 0;
    border-radius: 0.25rem;
  }
`;

// Drilling down css to child (Title).
const linkCustomCss = css`
  position: relative;
`;
// * CSS End

/**
 * The PopularCategoriesItem is a flight-component that is used by PopularCategories.
 *
 * @docauthor Fredrik Svärd @ R3
 */

export const PopularCategoriesItem = ({ image, linkUrl, linkText }) => {
  return (
    <PopularCategoriesItemContent>
      <div className={linkCustomCss}>
        {/* TODO - Change to Image ! */}
        <LinkEvaluator link={linkUrl}>
          <Image
            className="item-image"
            src={image?.value?.value}
            alt="placeholder"
            sizes="800"
            aspect={'5:2'}
          />
          <p className="item-text">{linkText?.value}</p>
          <div className="item-background-plate" />
        </LinkEvaluator>
      </div>
    </PopularCategoriesItemContent>
  );
};
