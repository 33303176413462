import React, { useRef, useState, useEffect } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import { ReactComponent as ChevronRight } from '../../svg/ChevronRight.svg';

const TitleWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: ${theme.maxWidth};
  margin: 0 auto 1rem auto;
  padding: 0;
  ${theme.below.lg} {
    padding: 0 20px;
  }

  .link-evaluator {
    width: fit-content;
    display: inline-flex;
    justify-self: end;
    align-self: end;
    height: 100%;

    .link-content-container {
      white-space: nowrap;
      display: flex;
      justify-self: end;
      align-self: end;
      gap: 5px;
    }

    p {
      line-height: 1;
      font-size: 14px;
      color: ${theme.colors.black};
      font-weight: ${theme.fontWeights.light};
      padding-bottom: 7px;
      padding-left: 10px;

      ${theme.below.lg} {
        padding-bottom: 5px;
        font-size: 14px;
      }
    }

    svg {
      width: 12px;
      height: 12px;
      margin-top: 1px;
      ${theme.below.lg} {
        width: 12px;
        height: 12px;
      }
      path {
        stroke: ${theme.colors.black};
      }
    }
  }

  h2 {
    font-family: ${theme.fonts.primary};
    width: 100%;
    text-align: ${props => props.alignment};
    font-size: ${theme.fontSizes.smallHeader};
    line-height: ${theme.lineHeights.smallHeader};
    font-weight: ${theme.fontWeights.semibold};
    color: ${theme.colors.black};

    ${theme.below.lg} {
      font-size: ${theme.fontSizes.smallHeader};
    }
  }

  &.center {
    h2 {
      padding-left: ${props => props.widthAdjustment + 'px'};
    }
  }
`;

export const Title = ({ text, alignment, link, linkText }) => {
  const linkRef = useRef(null);
  const [linkWidth, setLinkWidth] = useState(0);

  useEffect(() => {
    if (linkRef?.current?.clientWidth > 0) {
      setLinkWidth(linkRef?.current?.clientWidth);
    }
  }, []);

  return (
    <TitleWrapper
      className={alignment?.value?.toLowerCase() ?? 'center'}
      widthAdjustment={linkWidth}
      alignment={alignment?.value?.toLowerCase() ?? 'center'}
    >
      <h2>{text?.value}</h2>

      {link?.value && (
        <LinkEvaluator link={link}>
          <div className="link-content-container" ref={linkRef}>
            <p>{linkText?.value}</p>
            <ChevronRight />
          </div>
        </LinkEvaluator>
      )}
    </TitleWrapper>
  );
};
