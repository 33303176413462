import React from 'react';
import Image from '@jetshop/ui/Image';
import { styled } from 'linaria/react';
import { cx, css } from 'linaria';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import { BoxContent } from './BoxContent';
import { theme } from '../Theme';
import { Above } from '@jetshop/ui/Breakpoints';
import { ScrollSnapper } from '../ui/designUpdate/ScrollSnapper';
import { FinntackButton } from '../ui/FinntackButton';

const ImageBoxRowWrapper = styled('section')`
  &.fullwidth {
    max-width: 100%;
  }

  padding-left: ${props => props.gapsize};

  &.no-slider {
    padding-right: ${props => props.gapsize};
    .scroll-snapper {
      gap: ${props => props.gapsize};
      flex-direction: row;
      display: flex;
      ${theme.below.lg} {
        flex-direction: column;
      }
      .scroll-snapper-item {
        margin: 0;
        flex: 1;
        min-width: 0;
        max-width: 100%;
      }
    }
  }
`;

export const ImageBoxRow = ({
  fullWidth,
  boxPadding,
  mobileSlider,
  children
}) => {
  const gapSize = `${theme.spaceDict[boxPadding?.value]}px`;
  return (
    <Above breakpoint="lg">
      {matches => (
        <ImageBoxRowWrapper
          gapsize={gapSize}
          className={cx(
            fullWidth && 'fullwidth',
            mobileSlider?.value ? 'slider' : 'no-slider'
          )}
        >
          <ScrollSnapper
            childWidth={matches ? '25%' : '70%'}
            childGap={gapSize}
          >
            {children}
          </ScrollSnapper>
        </ImageBoxRowWrapper>
      )}
    </Above>
  );
};

const ImageBoxRowItemWrapper = styled('div')`
  position: relative;
  height: 100%;
  flex: 1;
`;

const boxTitleStyle = css`
  &.SMALL {
    h1,
    h2 {
      font-size: 24px;
      line-height: 1.3;
    }
  }

  &.MEDIUM {
    h1,
    h2 {
      font-size: 36px;
      line-height: 1.3;
    }
  }

  &.LARGE {
    h1,
    h2 {
      font-size: 46px;
      line-height: 1.2;
    }
  }

  &.EXTRA_LARGE {
    h1,
    h2 {
      font-size: 66px;
      line-height: 1.2;
    }
  }
`;

export const ImageBoxRowItem = ({
  image,
  imageRatio,
  mobileImage,
  mobileImageRatio,
  link,
  desktopTextColor,
  desktopTextShadowColor,
  mobileTextColor,
  mobileTextShadowColor,
  header,
  headerSize,
  text,
  textAlignment,
  buttonText,
  buttonTheme,
  horizontalPosition,
  verticalPosition
}) => {
  return (
    <Above breakpoint="lg">
      {matches => (
        <ImageBoxRowItemWrapper
          className={cx(boxTitleStyle, headerSize?.value)}
        >
          <LinkEvaluator link={link}>
            <Image
              alt={matches ? image?.value : mobileImage?.value}
              src={matches ? image?.value : mobileImage?.value}
              aspect={matches ? imageRatio?.value : mobileImageRatio?.value}
              cover
            />
            <BoxContent
              verticalPosition={verticalPosition}
              horizontalPosition={horizontalPosition}
              textColor={matches ? desktopTextColor : mobileTextColor}
              textShadowColor={
                matches ? desktopTextShadowColor : mobileTextShadowColor
              }
              text={text}
              header={header}
              textAlignment={textAlignment}
              buttonChildren={
                buttonText?.value
                  ? [
                      <FinntackButton className={buttonTheme?.value}>
                        {buttonText?.value}
                      </FinntackButton>
                    ]
                  : null
              }
              forType={'image-box'}
            />
          </LinkEvaluator>
        </ImageBoxRowItemWrapper>
      )}
    </Above>
  );
};
