import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
const PromoteBoxRowWrapper = styled('section')`
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(${props => props.childlength}, 1fr);
  gap: ${props => props.gap};
  max-width: ${theme.maxWidth};
  width: 100%;
  position: relative;

  ${theme.below.lg} {
    grid-template-columns: repeat(
      ${props => {
        if (props.childlength > 2) {
          return 2;
        }
        return 1;
      }},
      1fr
    );
    grid-template-rows: repeat(auto, 1fr);
    padding: 0 20px;
  }

  &:not(.fullwidth) {
    /* padding: 0 20px; */
  }

  > * {
    position: relative;
    justify-items: stretch;
  }

  .content-placer {
    border: 1px solid blue;
    padding: 0;
  }
`;

export const PromoteBoxRow = ({ gap = '10px', children }) => {
  const editedChildren = children.map(child => {
    return {
      ...child,
      props: {
        ...child.props,
        totalChildren: { value: children?.length }
      }
    };
  });

  return (
    <PromoteBoxRowWrapper childlength={children?.length} gap={gap}>
      {editedChildren}
    </PromoteBoxRowWrapper>
  );
};
