import { FinntackButton } from '../ui/FinntackButton';
import { YoutubeVideo } from './YoutubeVideo';
import { BrandsMarquee, BrandsMarqueeItem } from './BrandsMarquee';
import { CollageRow, CollageRowItem } from './CollageRow';
import { HtmlRow } from './HtmlRow';
import { ImageBoxRow, ImageBoxRowItem } from './ImageBoxRow';
import { ImageHero } from './ImageHero';
import { ProductRow, ProductRowItem } from './ProductRow';
import { FindifyProductRow } from './FindifyProductRow';
import { Spacer } from './Spacer';
import { Title } from './Title';
import { UspRow, UspRowItem } from './UspRow';
import { PopularCategories } from './PopularCategories';
import { PopularCategoriesItem } from './PopularCategoriesItem';
import { PromoteBoxRow } from './PromoteBoxRow';
import { PromoteBoxRowLinkItem } from './PromoteBoxRowLinkItem';
import { PromoteBoxRowTextItem } from './PromoteBoxRowTextItem';

export const allContentComponents = {
  IMAGEHERO: ImageHero,
  BUTTON: FinntackButton,
  USPROW: UspRow,
  USPITEM: UspRowItem,
  SPACER: Spacer,
  TITLE: Title,
  IMAGEBOXROW: ImageBoxRow,
  IMAGEBOXROWITEM: ImageBoxRowItem,
  PRODUCTROW: ProductRow,
  PRODUCTROWITEM: ProductRowItem,
  BRANDSMARQUEE: BrandsMarquee,
  BRANDSMARQUEEITEM: BrandsMarqueeItem,
  COLLAGEROW: CollageRow,
  COLLAGEROWITEM: CollageRowItem,
  VIDEOHERO: YoutubeVideo,
  HTMLROW: HtmlRow,
  POPULARCATEGORIES: PopularCategories,
  POPULARCATEGORIESITEM: PopularCategoriesItem,
  FINDIFYPRODUCTROW: FindifyProductRow,
  PROMOTEBOXROW: PromoteBoxRow,
  PROMOTEBOXROWLINKITEM: PromoteBoxRowLinkItem,
  PROMOTEBOXROWTEXTITEM: PromoteBoxRowTextItem
};
