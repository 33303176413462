import React from 'react';
import { Link } from 'react-router-dom';
import { css, cx } from 'linaria';
import { setVariableFromProp } from './HelpFunctions';
import { isRelativeUrl } from './HelpFunctions';

const boxLinkStyle = css`
  text-decoration: none;
  width: 100%;
  height: 100%;
`;

export const LinkEvaluator = ({ link, linkClickCallback, children }) => {
  const linkValue = setVariableFromProp(link);

  if (linkValue) {
    return typeof linkValue === 'string' && !isRelativeUrl(linkValue) ? (
      <a
        href={linkValue}
        className={cx(boxLinkStyle, 'external-link link-evaluator')}
        target={'_blank'}
        rel="noreferrer"
        onClick={e => {
          //additional function can be run when click on link (e.g closeNav)
          if (linkClickCallback) {
            linkClickCallback(e);
          }
        }}
      >
        {children}
      </a>
    ) : (
      <Link
        to={linkValue}
        className={cx(boxLinkStyle, 'internal-link link-evaluator')}
        onClick={e => {
          //additional function can be run when click on link (e.g closeNav)
          if (linkClickCallback) {
            linkClickCallback(e);
          }
        }}
      >
        {children}
      </Link>
    );
  }

  return (
    <div className={cx(boxLinkStyle, 'no-link link-evaluator')}>{children}</div>
  );
};
