import React from 'react';
import { styled } from 'linaria/react';
import useChannelCheck from '../hooks/useChannelCheck';

const Badge = styled('div')`
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 20px;
  background: #e1142d;
  color: white;
  padding: 2px 8px;
  text-align: center;
  z-index: 1;
  font-size: 12px;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;

  &.product-page {
    right: 10px;
    bottom: auto;
    top: 10px;
    left: auto;
    font-size: 16px;
    padding: 4px 12px;
  }
`;

export const SaleBadge = ({ price, previousPrice, productPage }) => {
  const { isB2B } = useChannelCheck();

  const priceValue = isB2B ? 'exVat' : 'incVat';
  if (previousPrice?.[priceValue] > price?.[priceValue]) {
    const salePercent = Math.round(
      (1 - price?.[priceValue] / previousPrice?.[priceValue]) * 100
    );
    if (salePercent < 15) {
      return null;
    }
    return (
      <Badge className={productPage ? 'product-page sale-badge' : 'sale-badge'}>
        -{`${salePercent}%`}
      </Badge>
    );
  }
  return null;
};
