import React from 'react';
import FindifyRecommendations from '../Findify/FindifyRecommendations';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const FindifyWrapper = styled('div')`
  max-width: 80rem;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0 auto;

  > div {
    padding: 0;
  }

  ${theme.below.lg} {
    max-width: 100%;
  }

  .swiper-button-next {
    right: 20px;
  }

  .swiper-button-prev {
    left: 20px;
  }
`;

export const FindifyProductRow = ({ findifyId, product }) => {
  const productData = product?.value;
  const findifyKey = findifyId?.value;
  return (
    <FindifyWrapper>
      <FindifyRecommendations
        slotIdentifier={findifyKey}
        product={productData}
        from={'FindifyProductRow'}
      />
    </FindifyWrapper>
  );
};
