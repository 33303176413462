import React from 'react';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import { theme } from '../Theme';

const FinntackWrapper = styled('div')`
  height: 40px;
  font-size: 14px;
  width: fit-content;
  padding: 0 2rem;
  cursor: pointer;
  border-radius: 5px;

  ${theme.below.md} {
    height: 35px;
    font-size: 11px;
    padding: 0 1.5rem;
  }

  &.BLACK {
    background: ${theme.colors.black};

    a,
    div {
      color: white;
    }
  }

  &.GREEN {
    background: green;

    a,
    div {
      color: white;
    }
  }
  &.BLUE {
    background: ${theme.colors.secondaryblue};

    a,
    div {
      color: white;
    }
  }

  &.WHITE {
    background: white;

    a,
    div {
      color: ${theme.colors.black};
    }
  }
  &.BLACK_OUTLINED {
    background: ${theme.colors.transparent};
    border: 1px solid ${theme.colors.black};

    a,
    div {
      color: ${theme.colors.black};
    }
  }
  &.BLUE_OUTLINED {
    background: ${theme.colors.transparent};
    border: 1px solid ${theme.colors.secondaryblue};

    a,
    div {
      color: ${theme.colors.secondaryblue};
    }
  }
  &.WHITE_OUTLINED {
    background: ${theme.colors.transparent};
    border: 1px solid ${theme.colors.white};

    a,
    div {
      color: ${theme.colors.white};
    }
  }

  :hover,
  :active {
    opacity: 0.8;
  }

  &.disabled {
    cursor: not-allowed;
    background: ${theme.colors.lightgrey};
  }
`;

const CenterButtonContent = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FinntackButton = ({
  testid,
  link,
  className,
  clickCallback,
  children,
  buttonText,
  buttonTheme
}) => {
  return (
    <FinntackWrapper
      className={cx(className ?? buttonTheme?.value, 'finntack-button-wrapper')}
      onClick={clickCallback}
      data-testid={testid || ''}
    >
      <LinkEvaluator link={link}>
        <CenterButtonContent>
          {children ?? buttonText?.value}
        </CenterButtonContent>
      </LinkEvaluator>
    </FinntackWrapper>
  );
};
